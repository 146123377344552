import React from "react";

const CheckMark = () => {
  return (
    <div className="flex-shrink-0">
      <svg
        className="w-5 h-5 text-blue-900"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export const OurBestServices = () => {
  return (
    <div className="lg:col-start-2 lg:max-w-2xl">
      <h4 className="mt-6 mb-2 font-extrabold leading-8 text-blue-900 dark:text-white sm:text-3xl sm:leading-9">
        Our Best Services
      </h4>
      <p className="text-base font-semibold leading-6 text-blue-900 uppercase">
        Jasa Konsultan Keuangan & Manajemen
      </p>
      <p className="text-base font-semibold leading-6 text-blue-900 uppercase">
        (Finance & Management Consulting Service)
      </p>

      <ul className="mt-6 space-y-3 font-semibold text-base text-left">
        <li className="flex items-start lg:col-span-1">
          <CheckMark />
          <p className="ml-3 leading-5 text-gray-600">
            MANAGEMENT KEUANGAN
          </p>
        </li>
        <li className="flex items-start lg:col-span-1">
          <CheckMark />
          <p className="ml-3 leading-5 text-gray-600">
            JASA INTERNAL AUDIT, PERENCANAAN BISNIS
          </p>
        </li>
        <li className="flex items-start lg:col-span-1">
          <CheckMark />
          <p className="ml-3 leading-5 text-gray-600">PENINGKATAN DAYA SAING</p>
        </li>
        <li className="flex items-start lg:col-span-1">
          <CheckMark />
          <p className="ml-3 leading-5 text-gray-600">
            ANALISA BISNIS DAN KEUANGAN
          </p>
        </li>
        <li className="flex items-start lg:col-span-1">
          <CheckMark />
          <p className="ml-3 leading-5 text-gray-600">
            PELATIHAN MANAGEMENT BISNIS
          </p>
        </li>
        <li className="flex items-start lg:col-span-1">
          <CheckMark />
          <p className="ml-3 leading-5 text-gray-600">
            PELATIHAN SDM
          </p>
        </li>
      </ul>
    </div>
  );
};
