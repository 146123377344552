import React from "react";
// import { Link } from "react-router-dom";

import NavBar from "../components/Navbar/NavBar";
// import heroImg from "../images/web-dev.svg";

export default function IndexLayout({ children }) {
  return (
    <>
      <NavBar />
      <div className="container mx-auto">
        <div className="mx-5">{children}</div>
      </div>
    </>
  );
}
