import React from "react";
// import Clients from "../components/Clients";
// import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
// import Portfolio from "../components/Portfolio";
import OurClients from "../components/OurClients";
import Services from "../components/Services";
import { useDocTitle } from "../components/CustomHook";
import IndexLayout from "../layout/IndexLayout";
import MucMap from "../components/MucMap";
// import PageTable from "../components/async/PageTable";

function Home() {
  useDocTitle("Beranda | Multi Utama Consultant");

  return (
    <div className="bg-white">
      <IndexLayout>
        <section id="hero">
          <Hero />
        </section>
        {/* <section id="pagetable">
          <PageTable />
        </section> */}
        <section id="about">
          <Intro />
        </section>
        <section id="services">
          <Services />
        </section>
        <section id="ourclients">
          <OurClients />
        </section>
        {/* <Clients /> */}
        {/* <Cta /> */}
        <MucMap />
        <Footer />
      </IndexLayout>
    </div>
  );
}

export default Home;
