import React from "react";
// import { Link } from "react-router-dom";

const PREFIX_IMG = "/assets/img/clients/";

const data = [
  {
    name: "Bank Mandiri",
    desc: "lorem ipsum etc etc",
    img: "bankmandiri.png",
  },
  {
    name: "BPR BDE Yogyakarta",
    desc: "lorem ipsum etc etc",
    img: "BPRBDEYogyakarta.jpg",
  },
  {
    name: "Bank BRI",
    desc: "lorem ipsum etc etc",
    img: "bri.jpeg",
  },
  {
    name: "BRI Syariah",
    desc: "lorem ipsum etc etc",
    img: "brisyariah.jpeg",
  },
  {
    name: "Bank BTN",
    desc: "lorem ipsum etc etc",
    img: "btn_compact.png",
  },
  {
    name: "Dinas Perindustrian dan Perdagangan Sleman",
    desc: "lorem ipsum etc etc",
    img: "dinasPerindustrianDanPerdaganganSleman.png",
  },
  {
    name: "D'Paragon",
    desc: "lorem ipsum etc etc",
    img: "dparagon.jpg",
  },
  {
    name: "PUPR",
    desc: "lorem ipsum etc etc",
    img: "pupr.png",
  },
  {
    name: "Star Hotel",
    desc: "lorem ipsum etc etc",
    img: "starhotel.jpeg",
  },
  {
    name: "Taru Martani",
    desc: "lorem ipsum etc etc",
    img: "TaruMartani_Logo_trans.png",
  },
  {
    name: "Tikamoon",
    desc: "lorem ipsum etc etc",
    img: "tikamoon.png",
  },
];

const Clients = () => {
  return (
    <>
      {data.map((client) => {
        const { name, img } = client;
        return (
          <div key={name} className="w-60 h-60 mx-4 my-8 flex flex-col justify-between items-center border-2 hover:border-2 hover:shadow-lg rounded-3xl">
            <div className="w-48 h-32 flex-grow flex items-center justify-center">
              <img
                alt="clients"
                className="max-w-48 max-h-32 w-full h-auto object-contain object-center mb-4"
                src={PREFIX_IMG + img}
              />
            </div>
            <h2 className="text-gray-900 font-medium title-font tracking-wide text-sm uppercase mb-4 text-center ">
              {name}
            </h2>
          </div>
        );
      })}
    </>
  );
};

const OurClients = () => {
  return (
    <>
      <section className="text-gray-600 body-font" id="ourclients">
        <div className="container px-5 py-24 mx-auto">
          <section data-aos="zoom-in-down">
            <div className="my-4 py-4">
              <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
                Client Kami
              </h2>

              <div className="flex justify-center">
                <div className="w-48 border-b-4 border-blue-900"></div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center">
              <Clients />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default OurClients;
