import React from "react";

const GMap = () => {
  return (
    <section className="mb-32">
      <div
        id="map"
        className="relative h-[480px] w-screen lg:w-auto overflow-hidden bg-cover bg-[50%] bg-no-repeat"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1830.630061156551!2d106.89305754734244!3d-6.155222399999991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f509ce310157%3A0x57ef750525f1e86!2sElantamireka%20Consulting%20Group!5e0!3m2!1sen!2sid!4v1701259953802!5m2!1sen!2sid"
          width="800"
          height="480"
          // style="border:0;"
          allowFullScreen=""
          loading="lazy"
          title="map"
        ></iframe>
      </div>
    </section>
  );
};

const MucMap = () => {
  return (
    <section className="text-gray-600 body-font" id="map">
      <div className="container px-5 mx-auto">
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
              Lokasi Kami
            </h2>

            <div className="flex justify-center">
              <div className="w-48 border-b-4 border-blue-900"></div>
            </div>
          </div>
          <div className="flex flex-wrap overflow-x-auto">
            {/* <div className="m-auto lg:mx-32 max-w-6xl p-2 md:p-12 h-5/6" id="about"> */}
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
              <GMap />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default MucMap;
