import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
// import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Beranda | Multi Utama Consultant");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route exact path="/page" element={HashLinkPage} /> */}
            <Route path="/kontak-kami" element={<Contact />} />
            {/* <Route path="/demo-produk" element={<DemoProduct />} />  */}
          </Routes>
        </ScrollToTop>
      </Router>
      <a
          href="https://wa.me/6282136842843?text=%5Bdikirim%20dari%20web%5D%0ANama%3A%0AAlamat%3A%0APerusahaan%3A%0ADeskripsi%20pesan%3A"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
    </>
  );
}


export default App;
