import React from "react";
import { Link } from "react-router-dom";
import heroImg from "../images/web-dev.svg";
import { OurBestServices } from "./OurBestServices";

const Hero = () => {
  return (
    <>
      <div className="container hero py-5 pt-24">
        <section className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
          <div
            className="lg:w-1/2 flex flex-col justify-center"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <h1 className="md:text-5xl text-3xl font-extrabold text-blue-900">
              We Help Businesses
            </h1>
            <h1 className="md:text-5xl text-3xl font-extrabold text-blue-900">
              To Grow Faster
            </h1>
            <div className="max-w-2xl text-xl font-semibold tracking-tight pt-4 mb-5 text-gray-500">
              We are a system repair service company with power, management and
              the development of small medium and upper enterprises supported by
              personnel experienced experts in various fields.
            </div>
            <OurBestServices />
            <div className="mt-4 mb-4 space-x-0 md:space-x-2 md:mb-8">
              <Link
                to="/kontak-kami"
                className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0"
              >
                Pelajari lebih jauh
                <svg
                  className="w-4 h-4 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <div
            className="flex lg:justify-end w-full lg:w-1/2"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <img
              alt="card img"
              className="rounded-t float-right duration-1000 w-full"
              src={heroImg}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero;
