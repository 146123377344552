import React, { useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import { Events, Link as LinkScroll } from "react-scroll";

const NavLinks = (props) => {
  const isHomeRoute = useMatch("/");

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      props.handleMnuScrollClick();
    });

    return () => {
      Events.scrollEvent.remove("begin");
    };
  });
  return (
    <>
      {isHomeRoute ? (
        <>
          <LinkScroll
            className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
            smooth="true"
            spy={true}
            delay={200}
            to="about"
          >
            Tentang Kami
          </LinkScroll>
          <LinkScroll
            className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
            smooth="true"
            spy={true}
            delay={200}
            to="services"
          >
            Layanan
          </LinkScroll>
          <LinkScroll
            className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
            smooth="true"
            spy={true}
            delay={200}
            to="ourclients"
          >
            Client Kami
          </LinkScroll>
        </>
      ) : (
        <Link
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
          to="/"
        >
          Beranda
        </Link>
      )}

      {/* <Link
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/kontak-kami"
      >
        Hubungi Kami
      </Link> */}
      <Link
        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth="true"
        // to="/demo-produk"
        to="/kontak-kami"
      >
        Hubungi Kami
      </Link>
    </>
  );
};

export default NavLinks;
